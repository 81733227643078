<template>
  <div>
    <swiper>
      <swiper-item v-for="(item, index) in banners" :key="index" >
        <!--<img :src="~images/goods1.png" alt="">-->
        <img class="banner" :src="item.url" alt />
      </swiper-item>
    </swiper>
  </div>
</template>

<script>
// @ is an alias to /src
import { Swiper, SwiperItem } from "@/components/common/swiper";

export default {
  name: "DetailSwiper",
  data() {
    return {
      currentIndex:1
    };
  },
  props: {
    banners: {
      type: Array,
      default: []
    }
  },
  components: {
    Swiper,
    SwiperItem
  },
  methods: {
    
  }
};
</script>
<style  scoped>
.banner {
  height: 10.506667rem;
  width: 100%;
}
</style>
